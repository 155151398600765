<template>
    <section>
      <Toast style="z-index: 9999;" />
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="lg:flex">
          <div class="flex gap-2 items-center w-full lg:w-6/12">
            <i class="pi pi-list"></i>
            <p class="text-lg font-bold">Listado ordenes de venta</p>
          </div>
          <div v-if="$can('pharmasan.ventas.ov.crear')" class="w-full lg:w-6/12 flex lg:justify-end">
            <Button @click="$router.push({name: 'pharmasan.ventas.ov.crear'})" size="small" label="Crear orden de venta" class="w-full lg:w-max" icon="pi pi-plus" />
          </div>
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <div class="w-full">
            <label class="text-xs text-gray-600" for="numero_orden">Número de orden</label>
            <InputText class="w-full " id="numero_orden" @keyup.enter="agregarOv" v-model="ordenSeleccionada" @keydown="validacionOrdenes($event)" />
            <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-auto">
              <div v-for="(orden, i) in ordenesFiltradas" :key="i" class="">
                <div class="flex items-center p-1 bg-blue-200 text-blue-600 rounded-md">
                  <p class="text-xs">{{ orden }}</p>
                  <button @click="eliminarOrden(orden)" class="mx-1"><i class="pi pi-times text-xs"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cliente">Cliente</label>
            <Dropdown
              id="cliente"
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.CardCode"
              :options="listadoClientes"
              optionLabel="CardName"
              optionValue="CardCode"
              :filter="true"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_inicio">Fecha inicio</label>
            <InputText
              id="fecha_inicio"
              type="date"
              class="w-full "
              v-model="filtros.fecha_inicio"
              :max="filtros.fecha_fin"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_fin">Fecha fin</label>
            <InputText
              id="fecha_fin"
              type="date"
              :min="filtros.fecha_inicio"
              class="w-full "
              v-model="filtros.fecha_fin"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="bodega">Bodega</label>
            <AutoComplete
              id="bodega"
              @item-select="agregarBodegasListado"
              class="w-full h-9"
              inputClass="w-full"
              v-model="bodegaBusqueda"
              :suggestions="bodegasFiltradas"
              dropdown
              @complete="buscarBodega($event)"
              field="name"
            />
            <div v-if="listadoBodegasBusqueda.length" class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-auto">
              <div v-for="(bodega, i) in listadoBodegasBusqueda" :key="i" class="">
                <div class="flex items-center p-1 bg-blue-200 text-blue-600 rounded-md">
                  <p class="text-xs">{{ bodega.name }}</p>
                  <button @click="eliminarBodegaBusqueda(bodega)" class="mx-1"><i class="pi pi-times text-xs"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600">Identificación paciente</label>
            <div class="w-full flex gap-1">
              <Dropdown
                @change="filtros.U_PHR_NumDcto = ''"
                class="w-4/12 tipoId rounded-md"
                panelClass="text-xs"
                v-model="filtros.U_ACS_TpoIdentf"
                :options="tiposId"
                optionLabel="U_ACS_TpoIdentf"
                optionValue="U_ACS_TpoIdentf"
              />
              <AutoComplete
                class="w-8/12"
                inputClass="w-full"
                v-model="filtros.U_PHR_NumDcto"
                :suggestions="listadoPacientes"
                @complete="buscarPaciente"
                field="name"
              />
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="modalidad">Modalidad</label>
            <Dropdown
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.U_PHR_ModContrato"
              :options="listadoModalidades"
              optionLabel="descripcion"
              optionValue="value"
              id="modalidad"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="modalidad">Estado</label>
            <Dropdown
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.DocStatus"
              :options="estados"
              optionLabel="name"
              optionValue="value"
              id="estado"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="flex items-end gap-2">
            <Button
              @click="listarOrdenes"
              size="small"
              class="rounded-md w-full"
              label="Buscar"
              icon="pi pi-search"
              :pt="{
                root: { style: 'height: 2.4rem' }
              }"
            />
            <Button
              @click="limpiarFiltros"
              size="small"
              severity="secondary"
              class="rounded-md w-full"
              label="Limpiar"
              :pt="{
                root: { style: 'height: 2.4rem' }
              }"
            />
          </div>
        </div>
        <div class="w-full my-6">
          <DataTable class="p-datatable-sm text-xs"
                     :value="ordenes.rows"
                     responsiveLayout="scroll"
                     selectionMode="single">
            <Column header="No. Orden" class="p-selectable-row">
              <template #body="{data}">
                <div class="text-center pt-3 idPadre">
                  <p class="text-blue-600">{{ data.Id }}</p>
                  <div class="flex gap-x-4 idHijo text-white transition ease-in-out duration-500">
                    <button @click="$router.push({ name: 'pharmasan.ventas.ov.ver', params: {id: data.Id} })" class=" underline italic">Ver</button>
                    <button v-if="data.DocStatus === 0 && $can('pharmasan.ventas.ov.editar') && !data.ovSap" @click="editarOv(data.Id)" class="underline italic">Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column header="# SAP">
              <template #body="{data}">
                <div class="text-center">
                  <div class="flex gap-1 items-center" v-tooltip="'Ov Sincronizada con SAP'" v-if="data.ovSap">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M3.02907 13.0776C2.7032 12.3958 2.7032 11.6032 3.02907 10.9214C3.16997 10.6266 3.41023 10.3447 3.89076 9.78084C4.08201 9.55642 4.17764 9.44421 4.25796 9.32437C4.44209 9.04965 4.56988 8.74114 4.63393 8.41669C4.66188 8.27515 4.6736 8.12819 4.69706 7.83426C4.75599 7.09576 4.78546 6.72651 4.89427 6.41844C5.14594 5.70591 5.7064 5.14546 6.41893 4.89378C6.72699 4.78497 7.09625 4.7555 7.83475 4.69657C8.12868 4.67312 8.27564 4.66139 8.41718 4.63344C8.74163 4.56939 9.05014 4.4416 9.32485 4.25747C9.4447 4.17715 9.55691 4.08152 9.78133 3.89027C10.3452 3.40974 10.6271 3.16948 10.9219 3.02859C11.6037 2.70271 12.3963 2.70271 13.0781 3.02859C13.3729 3.16948 13.6548 3.40974 14.2187 3.89027C14.4431 4.08152 14.5553 4.17715 14.6752 4.25747C14.9499 4.4416 15.2584 4.56939 15.5828 4.63344C15.7244 4.66139 15.8713 4.67312 16.1653 4.69657C16.9038 4.7555 17.273 4.78497 17.5811 4.89378C18.2936 5.14546 18.8541 5.70591 19.1058 6.41844M4.89427 17.5806C5.14594 18.2931 5.7064 18.8536 6.41893 19.1053C6.72699 19.2141 7.09625 19.2435 7.83475 19.3025C8.12868 19.3259 8.27564 19.3377 8.41718 19.3656C8.74163 19.4297 9.05014 19.5574 9.32485 19.7416C9.44469 19.8219 9.55691 19.9175 9.78133 20.1088C10.3452 20.5893 10.6271 20.8296 10.9219 20.9705C11.6037 21.2963 12.3963 21.2963 13.0781 20.9705C13.3729 20.8296 13.6548 20.5893 14.2187 20.1088C14.4431 19.9175 14.5553 19.8219 14.6752 19.7416C14.9499 19.5574 15.2584 19.4297 15.5828 19.3656C15.7244 19.3377 15.8713 19.3259 16.1653 19.3025C16.9038 19.2435 17.273 19.2141 17.5811 19.1053C18.2936 18.8536 18.8541 18.2931 19.1058 17.5806C19.2146 17.2725 19.244 16.9033 19.303 16.1648C19.3264 15.8709 19.3381 15.7239 19.3661 15.5824C19.4301 15.2579 19.5579 14.9494 19.7421 14.6747C19.8224 14.5548 19.918 14.4426 20.1093 14.2182C20.5898 13.6543 20.8301 13.3724 20.971 13.0776C21.2968 12.3958 21.2968 11.6032 20.971 10.9214" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round">
                        </path>
                      </g>
                    </svg>
                    <span class="font-bold">{{ data.ovSap }}</span>
                  </div>
                  <div v-tooltip="'Pago Sincronizado con SAP'" class="flex text-xs gap-1 items-center text-green-600 font-bold mt-1 justify-center" v-if="data.ovPagoSync">
                    <i class="pi pi-dollar text-sm"></i>
                    <p>{{ data.ovPagoSync }}</p>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="FechaContabilizacion" header="Fecha Cont./Doc">
              <template #body="{data}">
                  {{ dayjs(data.FechaContabilizacion).format('YYYY-MM-DD') }}
              </template>
            </Column>
            <Column field="CardName" header="Cliente"></Column>
            <Column field="U_PHR_NumDcto" header="Paciente">
              <template #body="{data}">
                <p>
                  {{ data.U_ACS_NmbPct }}
                </p>
                <p>
                  {{ data.U_ACS_TpoIdentf + ' - ' + data.U_PHR_NumDcto }}
                </p>
              </template>
            </Column>
            <Column field="U_PHR_ModContratoDesc" header="Modalidad/Motivo">
              <template #body="{data}">
              {{ data.U_PHR_ModContratoDesc + ' / ' + data.U_PHR_MotAutorizaDesc }}
              </template>
            </Column>
            <!-- <Column field="U_PHR_MotAutorizaDesc" header="Motivo"></Column> -->
            <Column field="U_PHR_BodegaNombre" header="Bodega"></Column>
            <Column field="fullName" header="Creado por">
              <template #body="{data}">
                <p>{{ data.fullName ? data.fullName.toUpperCase() : '' }}</p>
              </template>
            </Column>
            <Column header="No. pendiente">
              <template #body="{data}">
                <p class="text-center">
                  {{ data.DocStatus === 0 ? data.DocEntry : '--' }}
                </p>
              </template>
            </Column>
            <Column header="Estado">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" :class="data.DocStatus === 0 ? 'bg-green-300' : (data.DocStatus === 1 ? 'bg-blue-300' : 'bg-red-600 text-white')">
                  <p class="font-bold">
                    {{ data.DocStatus === 0 ? 'Abierto' : (data.DocStatus === 1 ? 'Cerrado' : 'Cancelado') }}
                  </p>
                </div>
              </template>
            </Column>
            <Column header="Acción">
              <template #body="{data}">
                <Button
                  @click='DescargarPdf(data.Id)'
                  severity="info"
                  v-tippy="{ content: 'Imprimir pdf' }"
                  rounded
                  :pt="{
                    root: { style: 'width: 2rem; height: 2rem' }
                  }"
                  icon="pi pi-file-pdf"
                />
                <Button
                  v-if="data.pago == 1"
                  class="ml-2"
                  @click='DescargarPago(data.Id)'
                  severity="success"
                  v-tippy="{ content: 'Imprimir pago' }"
                  rounded
                  :pt="{
                    root: { style: 'width: 2rem; height: 2rem' }
                  }"
                  icon="pi pi-dollar"
                />
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(ordenes.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
    </section>
</template>
<script>
  import dayjs from 'dayjs'
  import axios from 'axios'
  import Cookies from 'js-cookie'
  import OrdenService from '../../../services/orden.service'
  import ClientesService from '../../../services/clientes.service'
  import BodegasService from '../../../services/bodegas.service'
  import PacientesService from '../../../services/pacientes.service'
  import ModalidadContratoService from '../../../services/modalidad-contrato.service'
  import { useOrdenesStore } from '../../../stores/ordenes.store'
  import { onMounted, ref, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import { useStore } from 'vuex'
  export default {
    name: 'ListadoOrdenesVenta',
    setup () {
      // Servicios
      const _OrdenService = ref(new OrdenService())
      const _ClientesService = ref(new ClientesService())
      const _BodegasService = ref(new BodegasService())
      const _PacientesService = ref(new PacientesService())
      const _ModalidadService = ref(new ModalidadContratoService())
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const toast = useToast()
      // Referencias
      const ordenes = ref([])
      const listadoClientes = ref([])
      const bodegasFiltradas = ref([])
      const listadoPacientes = ref([])
      const listadoModalidades = ref([])
      const ordenesFiltradas = ref([])
      const ordenSeleccionada = ref()
      const tiposId = ref([])
      const limit = ref(10)
      const offset = ref(0)
      const bodegaBusqueda = ref()
      const listadoBodegasBusqueda = ref([])
      const filtros = ref({
        CardCode: null,
        fecha_inicio: '',
        fecha_fin: '',
        Id: null,
        U_PHR_Bodega: null,
        U_ACS_TpoIdentf: null,
        U_PHR_NumDcto: null,
        U_PHR_ModContrato: null,
        DocStatus: null
      })
      const estados = ref([
        {
          name: 'Abierto',
          value: 0
        },
        {
          name: 'Cerrado',
          value: 1
        },
        {
          name: 'Cancelado',
          value: 2
        }
      ])
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          ...filtros.value
        }
      })
      // Stores
      const ordenesStore = useOrdenesStore()
      // Metodos
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        listarOrdenes(params.value)
      }
      const listarOrdenes = () => {
        agregarOv()
        ordenesFiltradas.value.length ? filtros.value.Id = JSON.stringify(ordenesFiltradas.value) : filtros.value.Id = null
        const object = {
          ...params.value
        }
        if (listadoBodegasBusqueda.value.length) {
          object.U_PHR_Bodega = JSON.stringify(listadoBodegasBusqueda.value.map(a => a.WhsCode))
        }
        _OrdenService.value.listarOrdenes(object).then(({ data }) => {
          ordenes.value = data
        })
      }
      const editarOv = (id) => {
        router.push({ name: 'pharmasan.ventas.ov.editar', params: { id } })
      }
      const listarClientes = () => {
        _ClientesService.value.listarCLientes().then(({ data }) => {
          listadoClientes.value = data
        })
      }
      const listarTiposId = () => {
        _PacientesService.value.listarTiposId().then(({ data }) => {
          tiposId.value = data
        })
      }
      const buscarBodega = (event) => {
        _BodegasService.value.buscarBodegasOvs(event.query.toUpperCase() || 'a').then(({ data }) => {
          bodegasFiltradas.value = []
          for (const i of data) {
            const name = i.WhsCode + ' - ' + i.WhsName
            bodegasFiltradas.value.push({
              ...i,
              name
            })
          }
        })
      }
      const buscarPaciente = () => {

      }
      const listarModalidadesContrato = () => {
        _ModalidadService.value.listarModalidades().then(({ data }) => {
          listadoModalidades.value = data
        })
      }
      const agregarOv = () => {
        if (ordenSeleccionada.value) {
          for (const i of ordenSeleccionada.value.split(' ')) {
            if (ordenesFiltradas.value && ordenesFiltradas.value.length) {
              if (!ordenesFiltradas.value.some(a => a === i)) {
                i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
              }
            } else {
              if (i !== '') {
                i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
              }
            }
          }
        }
        ordenSeleccionada.value = ''
      }
      const validacionOrdenes = (e) => {
        if (/[^0-9\s]/.test(e.key)) {
          if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
            if (e.key === 'v' && !e.ctrlKey) {
              e.preventDefault()
            }
          } else {
            e.preventDefault()
          }
        }
      }
      const eliminarOrden = (numeroOv) => {
        const index = ordenesFiltradas.value.findIndex(a => a === numeroOv)
        ordenesFiltradas.value.splice(index, 1)
      }
      const limpiarFiltros = () => {
        filtros.value = {
          CardCode: null,
          fecha_inicio: '',
          fecha_fin: '',
          Id: null,
          U_PHR_Bodega: null,
          U_ACS_TpoIdentf: null,
          U_PHR_NumDcto: null,
          U_PHR_ModContrato: null
        }
        ordenesFiltradas.value = []
        ordenSeleccionada.value = ''
        listarOrdenes()
      }

      const agregarBodegasListado = () => {
        if (!listadoBodegasBusqueda.value.length || !listadoBodegasBusqueda.value.some(a => a.WhsCode === bodegaBusqueda.value.WhsCode)) {
          listadoBodegasBusqueda.value.push(bodegaBusqueda.value)
        }
        bodegaBusqueda.value = ''
      }

      const eliminarBodegaBusqueda = (bodega) => {
        listadoBodegasBusqueda.value = listadoBodegasBusqueda.value.filter(a => a.WhsCode !== bodega.WhsCode)
      }

      const DescargarPdf = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }

      const validarPermisos = (permission) => {
        const user = store.getters['auth/user']
        if (user.admin) {
          return true
        } else {
          const permissions = store.getters['auth/getPermissions']
          if (permissions.some(a => a === permission)) return true
          return false
        }
      }

      const DescargarPago = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pago-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }

      onMounted(() => {
        ordenesStore.obtenerBodegaPpalUsuario().then((data) => {
          if (!validarPermisos('pharmasan.ventas.ov.ver-todas')) {
            listadoBodegasBusqueda.value.push({
              ...data,
              name: data.idBodega + ' - ' + data.bodega.WhsName,
              WhsCode: data.idBodega
            })
          }
          if (route.query.ov_sincronizada) toast.add({ severity: 'warn', summary: 'Error', detail: `La orden de venta ${route.query.ov_sincronizada} ya se encuentra sincronizada con SAP`, life: 5000 })
          if (route.query.pago_sincronizado) toast.add({ severity: 'warn', summary: 'Error', detail: `El pago de la orden de venta ${route.query.pago_sincronizado} ya se encuentra sincronizado con SAP`, life: 5000 })
          if (route.query.ov_sincronizada || route.query.pago_sincronizado) {
            setTimeout(() => {
              router.replace({ query: null })
            }, 5000)
          }
          listarOrdenes()
        })
        listarClientes()
        listarTiposId()
        listarModalidadesContrato()
        var menu = document.getElementsByClassName('side-nav')
        var namePage = document.getElementsByClassName('name-page')
        if (menu[0].classList.contains('hidden')) {
          menu[0].classList.remove('hidden')
        }
        if (namePage[0].classList.contains('hidden')) {
          namePage[0].classList.remove('hidden')
        }
      })
      return {
        filtros,
        limit,
        offset,
        ordenes,
        onPage,
        listarOrdenes,
        dayjs,
        listadoClientes,
        bodegasFiltradas,
        tiposId,
        buscarBodega,
        listadoPacientes,
        buscarPaciente,
        listadoModalidades,
        ordenSeleccionada,
        agregarOv,
        validacionOrdenes,
        eliminarOrden,
        ordenesFiltradas,
        limpiarFiltros,
        DescargarPdf,
        DescargarPago,
        editarOv,
        estados,
        listadoBodegasBusqueda,
        agregarBodegasListado,
        bodegaBusqueda,
        eliminarBodegaBusqueda
      }
    }
  }
</script>
<style lang="scss">
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
  .p-selectable-row:hover {
    .idHijo{
      color: blue
    }
  }
</style>
